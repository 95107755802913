import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-child-edit',
  templateUrl: './child-edit.component.html',
  styleUrls: ['./child-edit.component.css']
})
export class ChildEditComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() grades;
  @Input() index;
  @Output() remove = new EventEmitter();



  constructor() { }

  ngOnInit() {
  }

  gender(value){
    this.formGroup.controls['Gender'].setValue(value);
  }

  removeChild(id) {
    this.remove.emit(id);
  }
}
