import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'app-upload-file',
  template: `
    <button class="upload-button" mat-raised-button (click)="Image.click()">
      {{label}}
    </button>
    <input #Image class="hidden" placeholder="Image" type="file" (change)="changeFile.next(Image)">
  `,
  styles: [
      `.hidden{display: none;}`,
      `.upload-button{margin-bottom: 10px;}`
  ]
})
export class UploadFileComponent {
  @Input('label') label;
  @Output() changeFile = new EventEmitter<any>();

}
