import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Input} from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  	constructor() {
  	}

  	ngOnInit() {

  	}

	@Output() event = new EventEmitter();

	hide() {
		console.log("nesto")
		this.event.emit(false);
	}
}

