import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-something-wrong',
  templateUrl: 'something-wrong.component.html',
  styleUrls: ['something-wrong.component.less'],
})

export class SomethingWrongComponent {
  @Input() error: string = 'Sorry, something is wrong';

  constructor() {
  }

}
