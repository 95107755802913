import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';

import { AppComponent } from './app.component';
import { UiModule } from './ui/ui.module';
import { RouterModule, Routes } from '@angular/router';
import { GlobalService } from './services/global-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from './pages/main/main.component';
import { NguCarouselModule } from '@ngu/carousel';
import { InnerHeaderComponent } from './ui/inner-header/inner.header.component';
import { DemoMaterialModule } from './ui/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpService } from './services/http.service';
import { CatalogService } from './services/catalog.service';
import { NgPipesModule } from 'ngx-pipes';
import { AuthGuard } from './services/auth.guard';
import { AdminGuard } from './services/admin.guard';
import {Angulartics2Module} from "angulartics2";
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './services/moment-utc-date-adapter';
import { AuthService } from './services/auth.service';

const routes: Routes = [
  {
    path: 'pages/personal',
    loadChildren: () => import('./personal/personal.module').then(m => m.PersonalModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages/auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'pages/admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'pages/public',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },

  {
    path: '',
    component: MainComponent,
    pathMatch: 'full'
  }

];

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YY',
    monthYearA11yLabel: 'MM YY',
  },
};


const Components = [
  MainComponent,
  AppComponent,

  InnerHeaderComponent,
];

@NgModule({
  declarations: Components,
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UiModule,
    Ng2CarouselamosModule,
    NguCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    DemoMaterialModule,
    NgPipesModule,
    MatDatepickerModule,
    MatMomentDateModule,
    Angulartics2Module.forRoot(),
  ],
  exports: [NguCarouselModule],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    //{ provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
    //{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    //{ provide: DateAdapter, useClass: MomentUtcDateAdapter },    
    GlobalService,
    HttpService,
    CatalogService,
    AuthService,
    AuthGuard,
    AdminGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
