<div class="maincontent">
  <div class="wrapper-menu">
    <a routerLink="/"><img src="/assets/images/global/logo.png"/></a>
    <div class="personalmenuintro-container">
      <div class="personalmenuintro">
        <ng-content></ng-content>
      </div>
    </div>
    <span class="profile-logout">
      <mat-icon
        (click)="goAlerts()"
        *ngIf="GS.auth.isAuth"
        matBadge="{{alerts}}"
        [matBadgeHidden]="!alerts && alerts===0">
        notifications
      </mat-icon>
    Hello, {{GS.auth.Email}}
    <a mat-icon-button (click)="logout()">
          <mat-icon>arrow_right_alt</mat-icon>
    </a>
  </span>
  </div>
</div>
