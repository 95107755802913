import {GlobalService} from "./global-service";
import {Injectable} from "@angular/core";
import {IGrade, IState} from "../interfaces";

@Injectable()
export class CatalogService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  readonly DEFAULT_MAX_AGE: number = 7200000; // 2 hours

  constructor(private GS: GlobalService) {
  }

  private get<T>(controller: string, method: string): Promise<T> {

    const key = `${controller}_${method}`;

    if (this.hasValidCachedValue(key)) {
      return new Promise((resolve => resolve(this.cache.get(key).value)));
    } else {
      return new Promise((resolve, reject) => {
        this.GS.postHttp<T, any>(controller, method)
          .then(
            res => {
              this.set(key, res);
              resolve(res);
            },
            rej => reject(rej)
          );
      });
    }
  }

  private clear(controller: string, method: string) {

    const key = `${controller}_${method}`;
    this.cache.delete(key);
  }

  private set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.cache.set(key, {value: value, expiry: Date.now() + maxAge});
  }

  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key).expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  getGrades(): Promise<IGrade[]> {
    return this.get<IGrade[]>('Catalogs', 'GetGrades');
  }

  getStates(): Promise<IState[]> {
    return this.get<IState[]>('Catalogs', 'GetStates');
  }

  clearStates() {
    this.clear('Catalogs', 'GetStates');
  }
}


interface CacheContent {
  expiry: number;
  value: any;
}
