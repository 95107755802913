import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
})
export class ButtonComponent{
  @Input() icon = false;
  @Input() text = 'Load More';
  @Input() color = 'blue';
  @Input() disabled = false;
  @Output() clickEvent = new EventEmitter<any>();

  @HostBinding('class.button--more') buttonMore: boolean = true;

  constructor() {}

  public buttonClick(e: MouseEvent) {
      if (!this.disabled){
        this.clickEvent.emit();
      } else {
        e.stopPropagation();
      }
    }


}
