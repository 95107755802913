<div id="reviews">
  <div id="reviews-carousel">

    <div class="wrapper">

      <h4 class="title">Reviews</h4>

      <div *ngFor="let reviews of reviewsData; let i = index"
           [@move]="state"
           (@move.done)="onFinish($event)"
           (@move.start)="onStart($event)"
           class="reviews-item {{i}}">

        <div class="review-item-wrapper {{reviews.type}}">

          <img [src]="reviews.src">
          <span *ngIf="reviews.type=='small'" class="datetime">{{reviews.date}}</span>
          <h4 class="name">{{reviews.name}}</h4>
          <p class="description">{{reviews.text}}</p>

        </div>

      </div>

      <a id="slider-buttons-left" (click)="moveLeft()">&#10094;</a>
      <a id="slider-buttons-right" (click)="moveRight()">&#10095;</a>

    </div>

  </div>

  <a href="#" class="button pink">Read all reviews</a>
</div>