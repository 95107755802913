<ng-container *ngIf="error" [ngSwitch]="error">

  <div class="alert warning" *ngSwitchCase="'AlreadyRegisteredException'">
    <p>{{ error | exception }}</p>
    <p><a [routerLink]="['/pages/auth/login']"> Login </a></p>
  </div>

  <div class="alert warning" *ngSwitchDefault>
    <p>{{ error | exception }}</p>
  </div>
</ng-container>
