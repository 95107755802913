import {Component} from '@angular/core';

@Component({
  selector: 'app-inner-header',
  templateUrl: 'inner.header.component.html',
  styleUrls: ['inner.header.component.css'],
})

export class InnerHeaderComponent {

}
