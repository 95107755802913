import {Component, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {GlobalService} from '../services/global-service';

@Component({
  selector: 'app-breadcrumbs',
  template: `
    <div class="breadcrumbs">
      <a routerLink="/">HOME</a>
      <div class="bcpages" *ngIf="pages">
        <div *ngFor="let p of pages">
        &nbsp;/&nbsp;<a [routerLink]="p.rout">{{p.name}}</a>
        </div>
      </div>
      <a>&nbsp;/&nbsp;{{endPageName}}</a>
    </div>
  `,
  styles: [`
    
    .breadcrumbs {
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      height: 74px;
      line-height: 74px;
      border-bottom:1px solid #e8e8e8;
    }
    .breadcrumbs a {
      color: #727272;
      display:inline-block;
    }
    .bcpages {
      display:inline-block;
    }
    @media (max-width: 1154px) {
    .breadcrumbs{
    margin:0 auto;
    padding-left:0;
    text-align:center;
    }
    }
    @media (max-width: 769px) {
      .breadcrumbs {
        height:auto;
      }
    }
    @media (max-width: 390px) {
      .breadcrumbs a{
        font-size:12px;
        letter-spacing:0px;
        line-height: 25px;
        display:inline-block;
      }
    }
  `]
})
export class BreadCrumbsComponent {
  @Input() pages;
  @Input() endPageName;

  constructor(private gs: GlobalService, private route: ActivatedRoute, private router: Router) {
  }
}
