import { Component, HostListener, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {GlobalService} from '../../services/global-service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { error } from '@angular/compiler/src/util';
import { IMainReview } from '../reviews/reviews.component';
import { timer } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.scss'],
})
export class MainComponent implements OnInit {
  block1Animate;
  block2Animate;
  block3Animate;
  block4Animate;
  block5Animate;
  block6Animate;
  block7Animate;
  block8Animate;
  block9Animate;

  isActive: boolean = false;

  public show: boolean = false;
  public buttonName: any = true;

  mainReviews: IMainReview[] = [];

  constructor(private gs: GlobalService,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer) {
    this.onResize();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {

    const verticalOffset = document.documentElement.scrollTop
        || document.body.scrollTop || 0;

    if (verticalOffset > 100) {
      this.block1Animate = true;
    }

    if (verticalOffset > 600) {
      this.block2Animate = true;
    }

    if (verticalOffset > 1500) {
      this.block3Animate = true;
    }

    if (verticalOffset > 2800) {
      this.block4Animate = true;
    }

    if (verticalOffset > 3500) {
      this.block5Animate = true;
    }

    if (verticalOffset > 4300) {
      this.block6Animate = true;
    }

    if (verticalOffset > 6800) {
      this.block7Animate = true;
    }

    if (verticalOffset > 7800) {
      this.block8Animate = true;
    }

    if (verticalOffset > 8700) {
      this.block9Animate = true;
    }

    // if (verticalOffset > 3700) {
    //   this.reviewsAnimate = true;
    // } else {
    //   this.reviewsAnimate = false;
    // }

    // if (verticalOffset > 4200) {
    //   this.shareAnimate = true;
    // } else {
    //   this.shareAnimate = false;
    // }

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const w = window.innerWidth;
    const k = Math.floor(w / 320);
  }

  ngOnInit() {
    timer(1000).subscribe(() => {
      this.isActive = true;
    });

    this.gs.postHttp('Review', 'GetMainReviews', {}).then((reviews: IMainReview[]) => {
      this.mainReviews = reviews.map((review: IMainReview) => {
        if (review.ImageFile !== null && review.ImageFile !== '') {
          review.ImageFile = this.sanitizer.bypassSecurityTrustStyle(`url('/images/reviews/${review.ImageFile}')`);
        }
        return review;
      }, () => {
        this.mainReviews = [];
      });
    });
  }

  toggle() {
      this.show = !this.show;
      this.buttonName = !this.show;

      window.scrollTo(0, 0);
  }
}
