import { Component, HostListener, OnDestroy } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {GlobalService} from '../../services/global-service';
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {ExceptionPipe} from "../../pipes/exception.pipe";

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})

// TODO: Скрыть меню по скролу https://gist.github.com/Elevista/465f98499ed7cb8528e3f724bbc24dec

export class HeaderComponent implements OnDestroy {
  public alerts: number = 0;
  public showmenu = true;
  public addClass = false;
  public barsText = "Menu";
  // state loader notifications
  public isNotificationsLoading = true;
  // State Color and ball in Header
  public stateBackGround: boolean;
  readonly _pageHeader: Array<string> = ['', 'mission', 'blog', 'for-school', 'about', 'faq'];
  private _unsubAll = new Subject<any>();


  constructor(
    public GS: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private exceptionPipe: ExceptionPipe,
  ) {
    this.menuResize();
    this.router.events
      .pipe(
        takeUntil(this._unsubAll),
        filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((r) => {
        const url = r.url.split('/').reverse()[0];
        this.__fixHeader(url);
      });

  }

  barsToggleClass() {
    this.addClass = !this.addClass;
    this.changeText();
  }

  changeText() {
    if (this.addClass) {
      this.barsText = "Close";
    } else {
      this.barsText = "Menu";
    }
  }

  exit() {
    this.GS.auth.clearAuth();
    this.router.navigate(['/']);
  }

  @HostListener('window:resize')
  menuResize() {
    const w = window.innerWidth;
    if (w <= 678) {
      this.showmenu = false;
    } else {
      this.showmenu = true;
    }
  }

  public async getAlerts() {
    if (!this.addClass || !this.GS.auth.isAuth) {return; }
    this.isNotificationsLoading = true;
    // try {
    //   this.alerts = await this.GS.postHttpAuth<number, any>('Alerts', 'GetCountNotifications');
    // } catch (e) {
    //   this._snackBar.open(this.exceptionPipe.transform(e), null, {duration: 2000});
    // }
    this.isNotificationsLoading = false;
  }

  private __fixHeader(url: string) {
    this.stateBackGround = this._pageHeader.indexOf(url) > -1;
  }

  ngOnDestroy(): void {
    this._unsubAll.next();
    this._unsubAll.complete();
  }

}
