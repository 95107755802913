import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuth: boolean = false;
  isAuth$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  Email: string = null;
  Role = 'User'; // User, Administrator
  RoleAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() {
    this.parseStorageAuth();
  }

  getToken() {
    const at = localStorage.getItem('AuthToken');
    if (!at) {
      return null;
    }
    const info = JSON.parse(at);
    return info.Token;
  }

  getRole() {
    const at = localStorage.getItem('AuthToken');
    if (!at) {
      return null;
    }
    const info = JSON.parse(at);
    return info.Role;
  }

  private parseStorageAuth() {
    const at = localStorage.getItem('AuthToken');
    if (!at) {
      return;
    }
    const token = JSON.parse(at);
    if (token) {
      this.Email = token.Email;
      this.isAuth = true;
      this.isAuth$.next(true);
      this.Role = token.Role;
      this.RoleAdmin$.next(this.Role === 'Administrator');
    }

  }

  setToken(token) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    localStorage.setItem('AuthToken', JSON.stringify({
      Name: decodedToken.Name,
      Role: decodedToken.Role,
      UserId: decodedToken.UserId,
      Email: decodedToken.Email,
      ActiveStudentsCount: decodedToken.ActiveStudentsCount,
      Token: token
    }));
    this.parseStorageAuth();
  }

  clearAuth() {
    localStorage.removeItem('AuthToken');
    this.isAuth = false;
    this.isAuth$.next(false);
  }
}
