<div class="children">
  <h5 class="subtitle">
    Child {{index + 1 }}
    <img *ngIf="index != 0"
         (click)="removeChild(index)"
         class="removechild"
         src="/assets/images/auth/about-kid/removechild.png">
  </h5>

  <form class="zoomy-form" [formGroup]="formGroup">

    <mat-form-field>
      <input matInput placeholder="Name" formControlName="Name" type="text">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Surname" formControlName="Surname" type="text">
    </mat-form-field>
    <ul class="children-gender">
      <li (click)="gender('girl')" [className]="formGroup.value.Gender === 'girl'? 'active': ''">
          <img src="/assets/icons/girl-icon.png"><span>Girl</span>
      </li>
      <li (click)="gender('boy')" [className]="formGroup.value.Gender === 'boy'? 'active': ''">
          <img src="/assets/icons/boy-icon.png"><span>Boy</span>
      </li>
      <li (click)="gender('doesnt')" [className]="formGroup.value.Gender === 'doesnt'? 'active': ''">
          <img src="/assets/icons/smile-icon.png"><span>Doesn't matter</span>
      </li>
    </ul>

    <mat-form-field>
      <input matInput [matDatepicker]="picker" (click)="picker.open()" (focus)="picker.open()" placeholder="Choose a date" formControlName="Birthday">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Grade</mat-label>
      <mat-select formControlName="Grade">
        <mat-option *ngFor="let grade of grades" [value]="grade.Id">
          {{grade.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>

</div>
