import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { IServerError } from 'src/app/services/http.service';
import { ExceptionPipe } from "../../pipes/exception.pipe";

@Component({
  selector: 'app-error-network',
  templateUrl: './error-network.component.html',
  styleUrls: ['./error-network.component.scss'],
})
export class ErrorNetworkComponent implements OnInit {
  @Input() error: boolean | string;
  @Input() errorServer: IServerError;
  @Output() refresh = new EventEmitter<any>();

  get errorCode() {
    if (this.errorServer) { return this.errorServer.status }
    else if (this.error) { return '500' }
  }  

  get errorMessage() {
    if (this.errorServer) { return this.exceptionPipe.transform(this.errorServer.message) }
    else if (this.error) { return this.exceptionPipe.transform(this.error) }
  }  

  @HostBinding('class.error-network') errorNetwork: boolean = true;

  constructor(public exceptionPipe: ExceptionPipe) {
  }

  ngOnInit() {
  }

  public reload(): void {
    this.refresh.next();
  }

}
