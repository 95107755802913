import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalService} from "../../services/global-service";
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {ExceptionPipe} from "../../pipes/exception.pipe";
import {Subject, timer} from "rxjs";
import {filter, takeUntil, takeWhile} from "rxjs/operators";

@Component({
  selector: 'app-common-inner-menu',
  templateUrl: 'common-inner-menu.component.html',
  styleUrls: ['common-inner-menu.component.css'],
})
export class CommonInnerMenuComponent implements OnInit, OnDestroy {
  public alerts: number = 0;
  private _unsubscribe = new Subject();

  constructor(
    public GS: GlobalService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private _exceptionPipe: ExceptionPipe,
  ) {

  }

  ngOnInit() {
    this._updateNotifications();
  }

  logout() {
    this.GS.auth.clearAuth();
    this.router.navigate(['/']);
  }


  public getAlerts() {
    if (!this.GS.auth.isAuth) {
      return;
    }
    this.GS.postHttpAuth<number, any>('Alerts', 'GetCountNotifications')
      .then((alert: number) => {
          this.alerts = alert;
        },
        (e) => {
          this._snackBar.open(this._exceptionPipe.transform(e), null, {duration: 2000});
        });
  }

  private _updateNotifications(): void {
    // TODO: Сделайте блин отключалку уже )) задолбало спамить, ваще нереально работать.
    //timer(500, 15000)
    //  .pipe(
    //    takeUntil(this._unsubscribe))
    //  .subscribe(() => this.getAlerts());
  }


  public goAlerts() {
    this.router.navigate(['/pages/personal/alerts']);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
