<div id="payment-calculation" *ngIf="bill">
  <h3 class="align-center">Order Summary</h3>
  <table>
    <tr>
      <td class="name">Plan name </td>
      <td>{{bill.PlanName}}</td>
    </tr>
    <tr>
      <td class="name">ZoomyBox for {{bill.StudentCount}} kids (${{bill.BoxCost}}/ box)</td>
      <td>${{bill.Sum | number:'.2'}}</td>
    </tr>
    <tr>
      <td class="name">Estimated Shipping</td>
      <td>${{bill.ShippingCost | number:'.2'}}</td>
    </tr>
    <tr>
      <td class="name">Estimated Tax</td>
      <td>${{bill.Tax | number:'.2'}}</td>
    </tr>

    <tr *ngIf="bill.CouponDiscount">
      <td class="name">Coupon or gift code</td>
      <td>${{bill.CouponDiscount | number:'.2'}}</td>
    </tr>

  </table>
  <div class="ordersummaryblock">
    <div class="totalprice">
      <div class="totalpricetitle">Total</div>
      <div class="totalpricedollar">${{bill.TotalSum | number:'.2'}}</div>
      <div class="totalpricetext">
        This amount will be charged before each shipment of MPie box. You can unsubscribe at any time.
      </div>
    </div>
  </div>
</div>
