import {Component, OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
  keyframes
} from '@angular/animations';

@Component({
  selector: 'app-reviews-slider',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css'],
  animations: [
    trigger('move', [
      state('void', style({transform: 'translateX(0)'})),
      state('left', style({transform: 'translateX(100%)'})),
      state('right', style({transform: 'translateX(-100%)'})),
      transition('void => left', [
        animate(200, style({transform: 'translateX(100%)'}))
      ]),
      transition('left => void', [
        animate(200, style({transform: 'translateX(0)'}))
      ]),
      transition('void => right', [
        animate(200, style({transform: 'translateX(-100%)'}))
      ]),
      transition('right => void', [
        animate(200, style({transform: 'translateX(0)'}))
      ])
    ])
  ]
})
export class ReviewsComponent implements OnInit {
  constructor() {
  }

  @Input() data: [any];

  public reviewsData: any;
  public state = 'void';
  public disableSliderButtons: boolean = false;

  ngOnInit() {
    this.reviewsData = this.data;
  }

  imageRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  moveLeft() {
    if (this.disableSliderButtons) {
      return;
    }
    this.state = 'right';
    this.imageRotate(this.reviewsData, true);
  }

  moveRight() {
    if (this.disableSliderButtons) {
      return;
    }
    this.state = 'left';
    this.imageRotate(this.reviewsData, false);
  }

  onFinish($event) {
    console.log($event);
    this.state = 'void';
    this.disableSliderButtons = false;
  }

  onStart($event) {
    this.disableSliderButtons = true;
  }
}