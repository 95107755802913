import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter, forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export class IChangeFileData {
  src: string;
  extension: string;
}

@Component({
  selector: 'app-upload-image-file-bg',
  templateUrl: 'upload-image-file-bg.component.html',
  styleUrls: ['upload-image-file-bg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImageFileBgComponent),
      multi: true,
    },
  ],
})
export class UploadImageFileBgComponent implements ControlValueAccessor {
  set value(image: string) {
    this._value = image;
    this.valueChange.emit(this._value);

    let imagePath: string = '';
    if (!this._value) {
      imagePath = this.emptyImage;
    } else if (image.split(':')[0] !== 'data') {
      imagePath = this.path + this._value;
    } else {
      imagePath = this._value;
    }
    this.imageSafe = this.sanitizer.bypassSecurityTrustStyle(`url('${imagePath}')`);
  }

  get value(): string {
    return this._value;
  }

  @Input() path: string;
  @Input() label;
  @Input() width: string;
  @Input() height: string;
  @Input() emptyImage: string = '/assets/images/global/no-image.jpg';

  imageSafe: SafeStyle;
  private _value: string = null;

  @Output() changeFile: EventEmitter<IChangeFileData> = new EventEmitter<IChangeFileData>();
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private sanitizer: DomSanitizer) {
    this.imageSafe = this.sanitizer.bypassSecurityTrustStyle(`url('${this.emptyImage}')`);
  }

  onChangeImage(image) {
    if (image.files.length) {
      const reader = new FileReader();

      const fileData: IChangeFileData = {src: null, extension: null};

      reader.onloadend = () => {
        fileData.src = reader.result as string;
        this.writeValue(fileData.src);
        this.changeFile.next(fileData);
      };

      fileData.extension = image.files[0].name.split('.').pop();

      reader.readAsDataURL(image.files[0]);
    }
  }

  onClickImage(image) {
    this.onTouched();
    image.click();
  }

  onChange: Function = (val: string) => {
  };
  onTouched: Function = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
  }
}
