import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateNumberFromString'
})
export class DateNumberFromStringPipe implements PipeTransform {
  transform(value: string): number {
    const initialDate: Date = new Date(value);
    return initialDate.getTime();
  }
}
