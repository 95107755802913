<div class="container--button">
  <button
    (click)="buttonClick($event)"
    [disabled]="disabled"
    [ngClass]="{'__white': color === 'white', '__green': color === 'green'}"
    class="container--button__btn">
    <img
      *ngIf="icon"
      src="../../../assets/icons/add.svg">
    {{text}}
  </button>
</div>
