import { NgModule } from '@angular/core';
import {ExceptionPipe} from "./exception.pipe";
import { DateNumberFromStringPipe } from './dateNumberFromString.pipe';

@NgModule({
  declarations: [ExceptionPipe, DateNumberFromStringPipe],
  exports: [ExceptionPipe, DateNumberFromStringPipe],
  providers: [ExceptionPipe, DateNumberFromStringPipe]
})
export class PipesModule { }
