import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-upload-image-file',
  template: `
    <img class="mat-elevation-z1" [ngStyle]="customStyle" style="cursor: pointer;" [src]="image" (click)="Image.click()" alt="" />
    <input #Image class="hidden" placeholder="Image" type="file" (change)="changeFile.next(Image)">
  `,
  styles: [
      `.hidden{display: none;}`,
      `.upload-button{margin-bottom: 10px;}`
  ]
})
export class UploadImageFileComponent {
  @Input() image: SafeResourceUrl;
  @Input() label;
  @Input() customStyle;
  @Output() changeFile = new EventEmitter<any>();
}
