import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';

@Component({
  selector: 'app-ui-selector',
  templateUrl: 'selector.component.html',
  styleUrls: ['selector.component.css']
})
export class SelectorComponent implements OnChanges{
  arr: any[];
  private _value: any;
  selected_label: string;
  @Output() valueChange = new EventEmitter();
  @Input() label: string;
  @Input() custom_array: any[];
  focus = false;

  @Input() set value(vis: any){
    this._value = vis;
    this.setLabel();
    this.valueChange.emit(this._value);
  }

  get value(){
    return this._value;
  }

  constructor() {}

  onFocus(){
    this.focus = true;
  }

  offFocus(){
    this.focus = false;
  }

  selectON(id){
    this.value = id;
    this.focus = false;
  }

  ngOnChanges(){
    this.load();
    this.setLabel();
  }

  load(){
    this.arr = this.custom_array;
  }


  setLabel(){
    if (this._value && this.arr){
      const value = this.arr.find(f => f.Id === this._value);
      if (value) {
        this.selected_label = value.Name;
      }
      else{
        this.selected_label = this.label;
        this._value = null;
      }
    }else {
      this.selected_label = this.label;
    }

  }

}
