
<footer class="footer-main">
  <div class="container">
    <div class="list-wrapper">
      <article>
        <h4>Thank you</h4>

        <ul class="footer-social-list">
          <li>
            <a href="https://www.instagram.com/zoomybox/" class="social-link" target="_blank"><span class="fa fa-instagram" ></span></a>
          </li>
          <li>
            <a href="https://www.facebook.com/zoomybox/" class="social-link" target="_blank"><span class="fa fa-facebook"></span></a>
          </li>
          <li>
            <a href="https://twitter.com/" class="social-link" target="_blank"><span class="fa fa-twitter"></span></a>
          </li>
          <li>
            <a href="https://www.youtube.com/" class="social-link" target="_blank"><span class="fa fa-youtube"></span></a>
          </li>
        </ul>

        <small>MPie, Inc. 2022 All rights Reserved</small>
      </article>

      <article>
        <h4>ZoomyBox</h4>
        <ul>
          <li><a routerLink="/">For home</a></li>
          <li><a routerLink="/pages/public/for-school">For school</a></li>
          <li><a routerLink="/pages/public/mission">Mission and Vision</a></li>
          <!--<li><a routerLink="#">Subscribe</a></li>-->
          <!--li><a routerLink="/pages/auth/join" [queryParams]="{gift:2}">Make a gift</a></li-->
          <li><a routerLink="/pages/public/contacts">Make a gift</a></li>
          <!--<li><a routerLink="#">Refer a friend</a></li>-->
        </ul>
      </article>

      <article>
        <h4>About</h4>
        <ul>
          <li><a routerLink="/pages/public/about">About us</a></li>
          <li><a routerLink="/pages/public/reviews">Reviews</a></li>
          <!--li><a routerLink="/pages/public/blog">Blog</a></li-->
        </ul>
      </article>

      <article>
        <h4>Policy</h4>
        <ul>
          <li><a routerLink="/pages/public/terms">Terms and <br>Private policy</a></li>
        </ul>
      </article>

      <article>
        <h4>Help</h4>
        <ul>
          <li><a routerLink="/pages/public/faq">FAQ</a></li>
          <li><a routerLink="/pages/public/contacts">Contacts</a></li>
        </ul>
      </article>
    </div>
  </div>
</footer>
