<div class="h_menu_wrapper">
  <div class="h_menu_select selected" (click)="onFocus()">
    <div class="label">{{selected_label}}</div>
  </div>
  <div *ngIf="focus" class="selected-menu">
    <ul class="h_menu">
      <li *ngFor="let row of arr" (click)="selectON(row.Id)">{{row.Name}}</li>
    </ul>
  </div>
</div>
<div *ngIf="focus" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; opacity: .5; z-index: 999"
     (click)="offFocus()"></div>
