import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Angulartics2GoogleAnalytics} from "angulartics2/ga";

@Component({
  selector: 'app-root',
  template: `
    <app-inner-header *ngIf="basic === false"></app-inner-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  `,
  styles: []
})
export class AppComponent {

  basic = undefined;

  constructor(private router: Router, angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics){
    angulartics2GoogleAnalytics.startTracking();
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd){
        this.basic = !e.url.includes('/pages/auth/join');
      }
    });
  }
}
