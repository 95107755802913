import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-payment-calculation',
  templateUrl: './payment-calculation.component.html',
  styleUrls: ['./payment-calculation.component.css']
})
export class PaymentCalculationComponent{
@Input() set  setBill(v: BillInfo)  {
  if (v) {
    this.bill = v;
  }
}
  public bill: BillInfo;

  recalc(bill: BillInfo) {
    this.bill = bill;
  }
}



export interface BillInfo
{
  PlanName: string;
  Tax: number;
  BoxCost: number;
  StudentCount: number;
  ShippingCost: number;
  CouponDiscount: number;
  Sum: number;
  TotalSum: number;
}
