import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exception'
})
export class ExceptionPipe implements PipeTransform {

  private exception = {
    InvalidPasswordException: 'You are already registered, but the entered password doesn’t match to any account. ' +
      'Enter the correct password or go through the password recovery procedure.',
    AlreadyRegisteredException: 'AlreadyRegisteredException',
    RegistrationSetStudentsException: 'RegistrationSetStudentsException',
    ExampleException: 'This is a test message, you see it, because, we pretended that you made a mistake.',
    UserNotFoundException: 'User not found',
    ServerErrorException: 'Server error'
  };

  transform(value: any, args?: any): any {
    return this.exception[value] ? this.exception[value] : 'Exception not found';
  }

}

export class ServerException{
  static Exception401: 'Exception401';
  static Exception403: 'Exception403';
}
