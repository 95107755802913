import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './global-service';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private GS: GlobalService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.GS.auth.RoleAdmin$.pipe(
      tap(isAdmin => {
        if (!isAdmin) {
          if (!this.GS.auth.isAuth$.getValue()) {
            this.router.navigate(['/pages/auth/login']);
          } else {
            this.router.navigate(['/']);
          }
        }
      }),
    );
  }

}
