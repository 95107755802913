import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.less']
})
export class DialogMessageComponent implements OnInit {
 public title: string;
 public html   = '';
  constructor(
    public dialogRef: MatDialogRef<DialogMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, html?: string}) {
    this.title = this.data.title;
    this.html = data.html;
  }

  ngOnInit() {
  }

}
