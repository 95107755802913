import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private router: Router, private auth: AuthService ) {}

  post<RESPONSE_TYPE, INPUT_TYPE>(url: string, param: INPUT_TYPE | null = null, state: FormState = null): Observable<RESPONSE_TYPE> {
    let headers = new HttpHeaders();
    if (!state) { state = new FormState(false); }
    return this.request<RESPONSE_TYPE, INPUT_TYPE>(url, headers, param, state);
  }

  postAuth<RESPONSE_TYPE, INPUT_TYPE>(url: string, param: INPUT_TYPE | null = null, state: FormState = null): Observable<RESPONSE_TYPE> {
    let headers = new HttpHeaders();
    if (!state) { state = new FormState(false); }
    const token = this.auth.getToken();
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
      return this.request<RESPONSE_TYPE, INPUT_TYPE>(url, headers, param, state);
    }
    else {
      return new Observable(result => {
        state.setLoading(false);
        state.serverError = { status: 401, error: 'Unauthorized', message: 'Token not found' };
        this.auth.clearAuth();
        throwError(state.serverError);
      });
    }
  }

  private request<RESPONSE_TYPE, INPUT_TYPE>(url: string, headers: HttpHeaders, param: INPUT_TYPE | null = null, state: FormState = null): Observable<RESPONSE_TYPE> {

    headers = headers.append('Content-Type', 'application/json');
    state.setLoading(true);
    return this.http.post<IHttpResponse<RESPONSE_TYPE>>(`/Backend/${url}`, JSON.stringify(param), {
      observe: 'body',
      responseType: 'json',
      headers: headers,
    }).pipe(
      catchError((httpError: HttpErrorResponse) => {
        state.setLoading(false);
        if (httpError.status === 401) {
          this.auth.clearAuth();
          return EMPTY;
        } else {
          state.serverError = {
            status: httpError.status,
            error: httpError.statusText,
            message: null,
          };
          return throwError(state.serverError);
        }
      }),
      switchMap((response: IHttpResponse<RESPONSE_TYPE>) => {
        state.setLoading(false);
        if (response.IsSuccess) {
          return of(response.Result);
        } else {
          state.serverError = { status: 500, error: response.Error, message: response.ErrorMessage };
          return throwError(state.serverError)
        }
      }),
    );
  }

}

export class ServerException {
  static Exception401: 'Exception401';
  static Exception403: 'Exception403';
}

export interface ILoadState {
  loading: boolean,
  error?: string
}

export class FormState {
  constructor(public loading: boolean, public serverError: IServerError = null, private form: FormGroup | AbstractControl = null) { }

  setLoading(enable: boolean) {
    if (enable) {
      this.loading = true;
      this.form?.disable();
      this.serverError = null;
    } else {
      this.loading = false;
      this.form?.enable();
      this.serverError = null;
    }
  }
}

export interface IServerError {
  status: number;
  error: string;
  message: string | null;
}

export interface IHttpResponse<T> {
  IsSuccess: boolean;
  Result: T;
  Error: string;
  ErrorMessage: string;
}
