import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {PopupComponent} from './popup/popup.component';
import {BreadCrumbsComponent} from './breadcrumbs.component';
import {SelectorComponent} from './selector/selector.component';
import {LoaderComponent} from './loader/loader.component';
import {ChildEditComponent} from './child-edit/child-edit.component';


import {ReviewsComponent} from './reviews/reviews.component';
import {ShareComponent} from './share/share.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import {DemoMaterialModule} from "./material.module";
import {FormErrorMessageComponent} from "./form-error-message/form-error-message.component";
import {PipesModule} from "../pipes/pipes.module";
import {ErrorNetworkComponent} from "./error-network/error-network.component";
import {CommonInnerMenuComponent} from "./common-inner-menu/common-inner-menu.component";
import {DialogConfirmComponent} from "./dialog-confirm/dialog-confirm.component";
import {PaymentCalculationComponent} from "./payment-calculation/payment-calculation.component";
import {DialogMessageComponent} from "./dialog-message/dialog-message.component";
import {ButtonComponent} from "./button/button.component";
import {UploadFileComponent} from "./upload-file/upload-file.component";
import { UploadImageFileComponent } from './upload-file/upload-image-file.component';
import { UploadImageFileBgComponent } from './upload-file/upload-image-file-bg.component';
import {SomethingWrongComponent} from "./something-wrong/something-wrong.component";

const Components = [
  HeaderComponent,
  FooterComponent,
  PopupComponent,
  BreadCrumbsComponent,
  SelectorComponent,
  LoaderComponent,
  SomethingWrongComponent,
  ChildEditComponent,
  ReviewsComponent,
  ShareComponent,
  FormErrorMessageComponent,
  ErrorNetworkComponent,
  CommonInnerMenuComponent,
  DialogConfirmComponent,
  PaymentCalculationComponent,
  DialogMessageComponent,
  ButtonComponent,
  UploadFileComponent,
  UploadImageFileComponent,
  UploadImageFileBgComponent,
];


@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, MatDatepickerModule, DemoMaterialModule, ReactiveFormsModule, PipesModule],
  declarations: Components,
  exports: [
    ...Components,
    PipesModule
  ] ,
  entryComponents: [
    DialogMessageComponent,
    DialogConfirmComponent,
  ],

})
export class UiModule {
}
