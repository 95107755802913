import {Component, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {GlobalService} from '../../services/global-service';
import {ExceptionPipe} from "../../pipes/exception.pipe";

@Component({
  selector: 'app-form-error-message',
  templateUrl: 'form-error-message.component.html',
  styleUrls: ['form-error-message.component.less'],
})

export class FormErrorMessageComponent {
  @Input() error: string;

  constructor() {
  }

}
