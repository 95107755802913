<header id="header" class="header-main" [ngClass]="{'fixed': addClass, 'header--color': stateBackGround}">
  <div class="container">
    <div id="header-logo" class="header-logo">
      <a routerLink="/"><img src="/assets/images/global/logo.png"/></a>
    </div>
    <div class="header--ball" *ngIf="stateBackGround">
      <img src="/assets/images/global/drop-box1.png">
    </div>
    <div class="header-main__container-nav">
      <a (click)="barsToggleClass(); getAlerts()" [ngClass]="{'opened': addClass}" class="hamburger">
        {{barsText}}
        <div class="hamburger__bars">
          <div class="hamburger__bars-wrapper">
            <span class="first-bar-line"></span>
            <span class="center-bar-line"></span>
            <span class="last-bar-line"></span>
          </div>
        </div>
      </a>
      <nav id="header-main-menu" class="header-main__nav" [ngClass]="{'opened': addClass}">
        <div class="bgr-img" style="background-image: url('/assets/images/global/nav-img.jpg');"></div>
        <div class="list-nav">
          <div>
            <ul class="main-list">
              <li class="menu-item"><a (click)="barsToggleClass()" routerLink="/">For home</a></li>
              <li class="menu-item"><a (click)="barsToggleClass()" routerLink="/pages/public/for-school">For school</a>
              </li>
              <li class="menu-item"><a (click)="barsToggleClass()" routerLink="/pages/public/mission">Mission and
                Vision</a></li>
              <!--li *ngIf="!GS.isAuth" class="menu-item"><a (click)="barsToggleClass()" routerLink="/pages/auth/join" [queryParams]="{gift:2}">Make a gift</a></li-->
              <li *ngIf="!GS.auth.isAuth" class="menu-item"><a (click)="barsToggleClass()" routerLink="/pages/public/contacts">Make a gift</a></li>
              <!--<li class="menu-item"><a (click)="barsToggleClass()" routerLink="#">Subscribe</a></li>-->
              <!--<li class="menu-item"><a (click)="barsToggleClass()" routerLink="#">Refer a friend</a></li>-->
              <li *ngIf="!GS.auth.isAuth" class="user-panel">
                <a class="login" routerLink="/pages/public/contacts" (click)="barsToggleClass()">Join</a>
                <br>
                <br>
                <!--<a routerLink="/pages/auth/login" (click)="barsToggleClass()">Log in</a>-->
              </li>
              <li *ngIf="GS.auth.isAuth" class="user-panel">
                <a class="user-panel__bell" routerLink="/pages/personal/alerts">
                  <div class="bell--alert"><img src="/assets/icons/bell.svg">
                    <app-loader class="bell--alert__loader" [size]="2" [color]="'blue'" *ngIf="isNotificationsLoading"></app-loader>
                    <span *ngIf="!isNotificationsLoading">{{alerts}}</span>
                  </div>
                </a>
                <a [routerLink]="GS.Role === 'User' ? '/pages/personal/profile_edit' : '/pages/admin/settings'"
                   class="hello">{{GS.Email}}</a>
              </li>
              <li *ngIf="GS.auth.isAuth" class="user-panel">
                <a (click)="exit()">Logout</a>
              </li>
            </ul>

            <div class="small-divider"></div>

            <ul class="small-font-list">
              <li><a (click)="barsToggleClass()" routerLink="/pages/public/about">About us</a></li>
              <li><a (click)="barsToggleClass()" routerLink="/pages/public/reviews">Reviews</a></li>
              <!--li><a (click)="barsToggleClass()" routerLink="/pages/public/blog">Blog</a></li-->
              <li><a (click)="barsToggleClass()" routerLink="/pages/public/terms">Terms and Private policy</a></li>
              <li><a (click)="barsToggleClass()" routerLink="/pages/public/faq">FAQ</a></li>
              <li><a (click)="barsToggleClass()" routerLink="/pages/public/contacts">Contacts</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
