import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ServerException } from "../pipes/exception.pipe";
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../ui/dialog-confirm/dialog-confirm.component';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

declare var document;

@Injectable()
export class GlobalService {
  subscribeOnly: boolean;
  public boxNumbers = 26;

  constructor(private httpClient: HttpClient, public dialog: MatDialog, public auth: AuthService, public http: HttpService, private router: Router) {}

  confirm(message: string, action: () => void) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, { data: message });
    dialogRef.afterClosed().subscribe(result => { if (result) { action() } });
  }

  private getHttpPromise<T>(controller: string, method: string, param: any, headers: any): Promise<T> {
    let request = null;

    const addr = '/Backend/' + controller + '/' + method;
    request = this.httpClient.post<any>(addr, param, {headers: headers}).toPromise();

    return new Promise<T>((resolve, reject) => {
      request
        .then(res => {
          if (res.IsSuccess) {
            resolve(res.Result);
          } else {
            reject(res.Error);
          }
        }, rej => {
          if (rej.status === 401) {
            this.auth.clearAuth();
            reject(ServerException.Exception401);
          } else if (rej.status === 403) {
            reject(ServerException.Exception403);
          } else {
            reject('ServerErrorException');
          }

        })
        .catch(() => {
          reject('ServerErrorException');
        });
    });
  }

  postHttpAuth<T, K>(controller: string, method: string, param?: K): Promise<T> {
    let headers = null;
    const token = this.auth.getToken();

    if (token) {
      headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
    }
    
    return this.getHttpPromise<T>(controller, method, param, headers);
  }

  postHttpAuthAutoState<T, K>(controller: string, method: string, state: ILoadState, param?: K): Promise<T> {
    let headers = null;
    const token = this.auth.getToken();

    if (token) {
      headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
    }

    return new Promise<T>((resolve) => {
      state.loading = true;
      state.error = null;
      this.getHttpPromise<T>(controller, method, param, headers).then(res => {
        resolve(res);
        state.loading = false;
      }, rej => { 
        state.error = rej;
        state.loading = false;
      }).catch(er => {
        state.error = er;
        state.loading = false;
      });
    });
    
  }

  postHttp<T, K>(controller: string, method: string, param?: K): Promise<T> {
    return this.getHttpPromise<T>(controller, method, param, null);
  }

  public navigateToHome() {
    if (this.auth.Role === 'User') {
      this.router.navigate(['/pages/personal/calendar']);
    } else {
      this.router.navigate(['/pages/admin/box-builder/books']);
    }
  }
}

export interface ILoadState {
  loading: boolean,
  error: string
}



