<section id="main-share">
  <div class="wrap">
    <h5 class="align-center">Share with friends</h5>
    <ul>
      <li>
        <a href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=http://109.95.209.92&display=popup&ref=plugin&src=share_button"
           target="_blank"><img src="/assets/images/pages/main/facebook.png"></a></li>
      <li><a href="https://twitter.com/intent/tweet?text=Hello%20world" target="_blank"><img
              src="/assets/images/pages/main/twitter.png"></a></li>
      <li><a href=""><img src="/assets/images/pages/main/instagram.png"></a></li>
    </ul>
  </div>

</section>